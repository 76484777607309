<template>
  <page-layout>
    <!-- 车辆备案 -->
    <!-- 搜索头 -->
    <search-header
      class="mt-24"
      :schemaData="searchFileds"
      @getDataList="handleFormSearch"
    />
    <!-- 列表 -->
    <div class="mx-24 mt-12 bg-white">
      <p-table
        dataKey="modal"
        rowKey="id"
        :dataSource="dataSource"
        :columns="tableColumns"
      ></p-table>
    </div>
  </page-layout>
</template>
<script>
import { defineComponent, reactive } from "vue";
import { getInitTable } from "@/hooks/form-hook";
import { Table } from "@wlhy-web-components/common";
import { searchFileds } from "./constants/index";
import { getColumns } from "./constants/table-config";
import { useEtcVehiclePageApi } from "@/apis/etc";
import useSearchForm from "@/hooks/UseSearchForm";

export default defineComponent({
  name: "vehicles-record",
  setup() {
    const tableColumns = reactive(getColumns());
    const tableDataSource = reactive(getInitTable());
    const { filter, handleFormSearch } = useSearchForm(searchFileds);

    const etcVehiclePage = useEtcVehiclePageApi();

    const getConsultingList = Table.useTableConfig({
      filter,
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          ...filter.value,
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        const res = await etcVehiclePage(data);
        return { records: res.list, total: res.total };
      }
    });

    return {
      tableColumns,
      searchFileds,
      handleFormSearch,
      getConsultingList,
      dataSource: tableDataSource
    };
  }
});
</script>
