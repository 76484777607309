/**
 * 搜索头
 */
export const searchFileds = [
  {
    type: "input",
    field: "plate_number",
    label: "车牌号",
    iw: 200
  },
  {
    type: "select",
    field: "status",
    label: "备案情况",
    options: [
      { value: "RECORD", label: "成功" },
      { value: "NO_RECORD", label: "失败" }
    ],
    iw: 200
  }
];
