import { buildApi } from "@/utils/ajax";

const basicApi = buildApi("/portal/company");

// ETC服务
// ETC开票列表
export const useInvoicePageApi = basicApi({
  url: "/invoice/page",
  withCredentials: true
});

// 车辆备案
export const useEtcVehiclePageApi = basicApi({
  url: "/etc_vehicle/page",
  withCredentials: true
});

// ETC开票导出
export const useInvoiceExportApi = basicApi({
  url: "/invoice/invoice_export",
  withCredentials: true
});

//ETC发票下载
export const useInvoiceDownloadApi = basicApi({
  url: "/invoice/download",
  withCredentials: true
});
//ETC发票下载
export const useInvoiceExportTableApi = basicApi({
  url: "/invoice/export",
  withCredentials: true
});

// 企业备案信息
export const useEtcQueryApi = basicApi({
  url: "/etc/query",
  withCredentials: true
});

// 企业备案
export const useEtcFilingApi = basicApi({
  url: "/etc/filing",
  withCredentials: true
});

// 是否开始使用
export const useEtcUseApi = basicApi({
  url: "/etc/use",
  withCredentials: true
});

// 获取biz下载地址
export const useOssEtcQueryTemplateApi = basicApi({
  url: "/oss/query_template",
  withCredentials: true
});

// 车辆黑名单分页查询
export const useEtcVehicleBlackListApi = basicApi({
  url: "/etc/vehicle_black_list/page",
  withCredentials: true
});

// 移除车辆黑名单
export const useEtcVehicleBlackListDeleteApi = basicApi({
  url: "/etc/vehicle_black_list/delete",
  withCredentials: true
});

// 新增车辆黑名单
export const useEtcVehicleBlackListCreateApi = basicApi({
  url: "/etc/vehicle_black_list/create",
  withCredentials: true
});
