//table列配置
export const getColumns = () => {
  const columns = [
    { type: "checkbox", hidden: true },
    {
      minWidth: 100,
      type: "seq",
      fixed: "left",
      align: "left",
      title: "序号"
    },
    {
      title: "车牌号",
      dataIndex: "plate_number",
      minWidth: 120
    },
    {
      title: "车牌颜色",
      dataIndex: "plate_color",
      minWidth: 120,
      customRender: text => <span> {text === "YELLOW" ? "黄色" : ""} </span>
    },
    {
      title: "备案情况",
      dataIndex: "status_name",
      minWidth: 120
    },
    {
      title: "更新时间",
      field: "update_time",
      // minWidth: 120,
      cellRender: "p-date"
    }
  ];
  return columns;
};
